import React from "react"
export const Feature50DataSource = {
  wrapper: { className: "home-page-wrapper content7-wrapper" },
  page: { className: "home-page content7" },
  OverPack: {},
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>A platform built to support your workflows.&nbsp;</p>
          </span>
        ),
        className: "title-h1",
      },
    ],
  },
  tabsWrapper: { className: "content7-tabs-wrapper" },
  block: {
    children: [
      {
        name: "block0",
        tag: {
          className: "content7-tag",
          text: {
            children: (
              <span>
                <p>Pre-Recorded Interviews</p>
              </span>
            ),
            className: "content7-tag-name",
          },
          icon: { children: "mobile" },
        },
        content: {
          className: "content7-content",
          text: {
            className: "content7-text",
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <span>
                    <h3>Pre Qualify Candidates Faster</h3>
                    <p>
                      You want to interview as many candidates as possible.
                      Pre-Recorded Interviews increase your capacity and qualify
                      more candidates than ever before.&nbsp;
                    </p>
                    <br />
                    <h3>Engage the Right Candidates</h3>
                    <p>
                      By interviewing more candidates than ever, you will be
                      able to confidently engage the best fit candidates and
                      move them to the next step.&nbsp;
                    </p>
                    <br />
                    <h3>Deliver Convenience to Hiring Authorities</h3>Hiring
                    Authorities are busy. Make their lives easier by sharing
                    videos of candidates in an easy to use portal, allowing them
                    to view recordings + leave feedback immediately.&nbsp;
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: "content7-img",
            children:
              "https://deephire.s3.amazonaws.com/websiteAssets/candidate-view.gif",
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: "block1",
        tag: {
          className: "content7-tag",
          icon: { children: "tablet" },
          text: {
            className: "content7-tag-name",
            children: (
              <span>
                <p>Live Interviews</p>
              </span>
            ),
          },
        },
        content: {
          className: "content7-content",
          text: {
            className: "content7-text",
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <h3>All Information Needed, In One Place</h3>
                  <p>
                    Every candidates get a fair shot. Resumes, ID's, Reference
                    Checks... All candidate documents gets displayed + shared to
                    the right people, at the right time.&nbsp;
                  </p>
                  <br />
                  <h3>Streamlines The Interviewing Process</h3>
                  <p>
                    Now your team has one source of truth for the entire
                    interviewing process, and is able to schedule Send Out
                    Interviews in one click.&nbsp;
                  </p>
                  <br />
                  <h3>Share Top Candidates and Gain Insight</h3>Recorded Live
                  Interviews means you can provide coaching opportunities to
                  recruiters, and gain insight into what questions are asked
                  throughout the interviewing process.&nbsp;
                </span>
              </span>
            ),
          },
          img: {
            className: "content7-img kevlxo7jhlg-editor_css",
            md: 10,
            xs: 24,
            children:
              "https://deephire.s3.amazonaws.com/misc/mikey-vid-interview-landing.png",
          },
        },
      },
      {
        name: "block2",
        tag: {
          className: "content7-tag",
          text: {
            children: (
              <span>
                <span>
                  <p>Hiring Portal</p>
                </span>
              </span>
            ),
            className: "content7-tag-name",
          },
          icon: { children: "laptop" },
        },
        content: {
          className: "content7-content",
          text: {
            className: "content7-text",
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <h3>
                          <span>
                            Get the Feedback you Need, and your Candidate
                            deserves.&nbsp;
                          </span>
                        </h3>
                        <p>
                          Candidates hate black holes, and you hate long
                          followup times. The Hiring Portal allows you to share
                          interview recordings and get feedback
                          immediately.&nbsp;
                        </p>
                        <h3>All the Information Hiring Authorities Need.</h3>
                        <p>
                          Someone has a question, or needs more information
                          about a candidate? All answers are a click of a button
                          away with attached documents.&nbsp;
                        </p>
                        <h3>Have a Clear Next Step.&nbsp;&nbsp;</h3>
                        <p>
                          Our robust Hiring Portal helps Hiring Authorities make
                          a decision and leave feedback immediately, allowing
                          you to get back to work.&nbsp;
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: "content7-img",
            md: 10,
            xs: 24,
            children:
              "https://deephire.s3.amazonaws.com/websiteAssets/client-portal.gif",
          },
        },
      },
    ],
  },
}
export const Content00DataSource = {
  wrapper: { className: "home-page-wrapper content0-wrapper" },
  page: { className: "home-page content0" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <span>
              <p>A Simple and Unique Candidate Experience</p>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: "content0-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>One Click Interviews</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        All DeepHire interviewing technology is One Click,
                        meaning no downloads or log ins.&nbsp;
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>Auto Follow Ups</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    Candidates get tasteful reminders to help prepare them for
                    their upcoming interviews.&nbsp;
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>24/7 Tech Support</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    Never hear about tech issues again. We do all tech support,
                    so you don't have to.&nbsp;
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
}
export const Content10DataSource = {
  wrapper: { className: "home-page-wrapper content1-wrapper" },
  OverPack: { className: "home-page content1", playScale: 0.3 },
  imgWrapper: { className: "content1-img", md: 10, xs: 24 },
  img: {
    children:
      "https://deephire.s3.amazonaws.com/websiteAssets/undraw_in_sync_xwsa.svg",
  },
  textWrapper: { className: "content1-text", md: 14, xs: 24 },
  title: {
    className: "content1-title",
    children: (
      <span>
        <p>Integrate into your ATS.&nbsp;</p>
      </span>
    ),
  },
  content: {
    className: "content1-content",
    children: (
      <span>
        <p>
          DeepHire integrates into all major ATSs for a seamless workflow across
          systems. Spend more time doing the job you love, and less time
          fighting with software.&nbsp;
        </p>
      </span>
    ),
  },
}
export const Feature20DataSource = {
  wrapper: { className: "home-page-wrapper content2-wrapper" },
  OverPack: { className: "home-page content2", playScale: 0.3 },
  imgWrapper: { className: "content2-img", md: 10, xs: 24 },
  img: {
    children:
      "https://deephire.s3.amazonaws.com/websiteAssets/undraw_powerful_26ry.svg",
  },
  textWrapper: { className: "content2-text", md: 14, xs: 24 },
  title: {
    className: "content2-title",
    children: (
      <span>
        <span>
          <span>
            <p>Pull away from the market.&nbsp;</p>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: "content2-content",
    children: (
      <span>
        <span>
          <span>
            <p>
              A cohesive interviewing experience for candidates is what
              separates the average from the great. DeepHire unifies your
              interviewing efforts, creating consistency for candidates and less
              work for your team - meaning more placements, faster.&nbsp;
            </p>
          </span>
        </span>
      </span>
    ),
  },
}
export const Content110DataSource = {
  OverPack: {
    className: "home-page-wrapper content11-wrapper",
    playScale: 0.3,
  },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "image",
        children:
          "https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg",
        className: "title-image",
      },
      {
        name: "title",
        children: (
          <span>
            <p>Ready to Become More Effective?&nbsp;</p>
          </span>
        ),
        className: "title-h1",
      },
      {
        name: "content",
        children: (
          <span>
            <p>
              DeepHire is more than just software - with our Video Interviewing
              platform, you get a playbook for success.&nbsp;
            </p>
          </span>
        ),
        className: "title-content",
      },
    ],
  },
  button: {
    className: "",
    children: {
      a: {
        className: "button",
        href: "https://recruiter.deephire.com/user/login?signupEmail=%20",
        children: (
          <span>
            <p>Start Now</p>
          </span>
        ),
      },
    },
  },
}
